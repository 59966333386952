
body {
    font-family: "Source Sans Pro";
}

.guide-text {
    text-align: center;
}

.week {
    width: 30px;
    margin: 2px;
}

.calendar {
    border-radius: 4px;
    margin: 2px;
    width: 30px;
    height: 30px;
}

.chapter {
    height: 20px;
    width: 20px;
    margin-right: 3px;
    margin-top: 3px;
    margin-bottom: 3px;
    border-radius: 4px;
}

.chapter-book-name {
    height: 20px;
    width: 40px;
    margin-right: 6px;
    margin-top: 3px;
    margin-bottom: 3px;
    text-align: right;
    font-size: small;
}

.theme-color {
    width: 10px;
    height: 10px;
    margin: 1px;
    border-radius: 2px;
}

.gap {
    margin-left: 10px;
    margin-right: 10px;
}

.gap-left {
    margin-left: 10px;
}

.gap-right {
    margin-right: 10px;
}

.gap-book-chapter {
    margin-left: 5px;
    margin-right: 5px;
}

.gap-quiz {
    margin-top: 10px;
    margin-bottom: 10px;
}

.item {
  float: left;
}

.image-button:hover {
  cursor: pointer;
}

.color-overlay {
    position: absolute;
    background-color: whitesmoke;
    height: 100%;
    width: 100%;
    mix-blend-mode: normal;
}

.form {
    background-color:  white;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
}